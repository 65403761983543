// sass-lint:disable no-misspelled-properties no-qualifying-elements
.sc-card-child {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 20px;
  border: 1px solid $andes-gray-070-solid;
  box-shadow: 0 6px 16px $box-shadow;
  cursor: pointer;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &-label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: 18px;
  }

  &-title-subtitle.andes-typography--type-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: $font-weight-semibold;
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    width: 100%;
    border-top: 1px solid $andes-gray-100;

    .icon-text {
      gap: 4px;

      > div {
        height: 20px;
      }

      svg {
        margin: auto;
      }

      svg path {
        fill: $andes-gray-550;
      }

      svg.sc-guide-book-icon path {
        fill: transparent;
      }

      p {
        font-weight: $font-weight-semibold;
        font-size: 12px;
        line-height: 15px;
        color: $andes-gray-550;
        margin: 0;
      }
    }
  }
}

.sc-card-child-fallback {
  display: flex;
  align-items: center;
  width: 100%;

  &-img {
    width: 60px;
    height: 54px;
    margin-right: 16px;
  }

  &-subtitle {
    width: 276px;
    font-weight: $font-weight-semibold !important;
  }

  &-link {
    width: 150px;
    font-size: $font-size-14 !important;
    text-align: end;
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}
