.sc-card-child {
  height: auto;
  width: 100%;
  padding: 16px;
  margin-top: 20px;
}

.sc-card-child-fallback {
  flex-direction: column;

  &-img {
    width: 110px;
    height: 88px;
    margin-right: 0;
  }

  &-subtitle {
    margin-right: 0;
    max-width: 180px;
    text-align: center;
    margin-bottom: 8px;
  }

  &-link {
    text-align: center;
    margin-bottom: 4px;
  }
}
