// sass-lint:disable class-name-format no-misspelled-properties
.sc-card-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-right: 1px solid $andes-gray-100;
  width: 100%;

  .Book20 > svg {
    width: 30px !important;
    height: 30px !important;
  }

  .GlobalIcon > svg {
    width: 30px !important;
    height: 30px !important;
  }

  &:nth-child(3) {
    border-right: none;
  }

  &:last-child {
    border-right: none;
    border-left: 1px solid $andes-gray-100;
  }

  .icon-custom {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 8px;
    background-color: $andes-blue-100;
    display: inline-block;
  }

  .andes-typography--type-title {
    width: 200px;
    margin: 0;
    line-height: 20px;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
  }

  .Rocket40 {
    display: contents;
  }
}

.sc-card-icon:last-child .icon-custom {
  svg {
    width: 48px;
    height: 48px;
  }

  svg rect {
    width: 48px;
    height: 48px;
    rx: 24px;
  }

  svg g {
    transform: scale(1.2);
  }
}

.sc-card-container-content:empty,
.sc-card-container-text:empty {
  display: none;
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}
