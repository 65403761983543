.sc-home-header {
  background-position: unset;
  margin-bottom: 90px;

  .sc-home-header-content {
    width: 100%;
  }

  &-container {
    padding: 48px 20px;

    .home-title.andes-typography--type-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 28px;
    }

    .home-subtitle.andes-typography--type-body {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .sc-card-container {
    width: 100%;
  }
}
