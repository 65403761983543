.sc-card-child {
  height: 127px;
  width: 552px;
  padding: 24px;

  .sc-card-child-content {
    width: 412px;
  }
}

@media (max-width: $desktop-m) {
  .sc-card-child {
    width: auto;
  }

  .sc-card-child .sc-card-child-content {
    width: 306px;
  }
}
