// sass-lint:disable no-misspelled-properties no-color-literals
.sc-section-container {
  &-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  &-content {
    display: flex;
    align-items: center;

    .icon-custom {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-bottom: 8px;
      padding: 8px;
      background-color: $andes-blue-100;
    }

    .icon-custom svg {
      width: 30px;
      height: 30px;
    }

    &.right {
      align-items: flex-start;
    }

    &.left {
      align-items: flex-start;
    }
  }

  &-categories {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .andes-tabs__container {
      width: 100%;
    }
  }

  &-category {
    margin: 10px;
    cursor: pointer;
  }

  .cta-button {
    min-width: unset;
  }

  .sc-reco-container-fallback:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sc-reco-container-fallback:nth-child(3) {
    margin-top: 0;
  }

  .sc-reco-container:nth-child(2) {
    margin-top: 56px;
  }
}

.guide.sc-section-container {
  background-image: linear-gradient(
      360deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 20%,
      rgb(255 255 255 / 0%) 50%
    ),
    url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/gradient-F5F5F5.svg'),
    url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/waves-E1F4FC.svg');
  background-origin: border-box;
  background-position: top, top, bottom;
  background-repeat: no-repeat;
  background-size: cover, cover, contain;

  &-cards {
    max-width: 1129px;
  }
}

.sc-section-container.sc-intro-container {
  .sc-section-container-content {
    .andes-typography--type-title {
      margin: 0;
    }
  }

  .sc-reco-container-cards {
    display: flex;
    justify-content: center;
    gap: 16px;

    .categories-carrousel.sc-carrousel {
      margin-top: 0;
    }
  }

  .sc-section-container-cards {
    text-align: center;
  }
}

.sc-section-container.sc-categories-container,
.sc-section-container.sc-categories-container-bg {
  margin-bottom: 80px;

  .sc-section-container-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1129px;
    margin: 40px auto 45px;
  }

  &-cards {
    max-width: 1129px;
  }
}

.sc-section-container.sc-profile-container {
  background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/solid-F5F5F5-perfil.svg');

  .sc-section-container-content {
    .andes-typography--type-title {
      margin: 0;
    }
  }
}

.sc-find-information {
  .sc-initial-information-info {
    .andes-typography--type-title {
      margin: 0;
    }

    p {
      padding-top: 0 !important;
    }

    .sc-initial-information-info-item {
      .andes-typography.label {
        margin: 0;
      }
    }
  }
}

.sc-initial-information-info {
  &-item {
    .sc-find-container-title {
      .andes-typography--type-title {
        margin: 0;
      }
    }
  }
}

.sc-section-container-wrapper .andes-spinner {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modules.sc-section-container {
  .sc-section-container-content {
    margin-bottom: 32px;
  }
}

.sc-section-container-fallback {
  width: 100%;
  height: 206px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  &-img {
    width: 110px;
    height: 88px;
  }

  &-subtitle {
    width: 300px;
    text-align: center;
    font-weight: $font-weight-semibold;
    padding-bottom: 8px;
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}
