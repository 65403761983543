@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.floating-card,
.floating-card-completed {
  border-radius: 20px;
  position: relative;

  .andes-card__content {
    display: flex;
    align-items: center;
    height: 100%;
    @media (min-width: $desktop-m) {
      height: 130px;
    }
  }

  .cta-button .second-icon {
    path {
      fill: $andes-white;
    }
  }
}

.floating-info {
  width: 100%;

  .floating-info-content {
    font-weight: 600;
  }

  .floating-info-children {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 26px;

    .icon-text {
      justify-content: start;

      .icon-custom {
        height: 24px;
      }

      .andes-typography {
        margin: 0 0 0 12px !important;
        font-size: 14px;
        margin-left: 12px;
        color: $andes-gray-550;
      }
    }
  }
}

.floating-card-completed {
  .cta-button {
    padding: 0 20px;
    height: 54px;
  }
}
