.sc-initial-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  &-image {
    margin-top: 0;
    width: 90%;
    margin: auto;
  }

  &-info {
    padding-top: 40px;

    &-item:first-child {
      margin-bottom: 32px;
      max-width: 328px;

      .sc-find-container-title {
        width: 100%;
        display: inline-flex;
        align-items: center;

        .andes-typography {
          font-size: 24px;
        }
      }

      .icon-custom {
        padding: 5px;
        width: 30px;
        height: 30px;
        background: $andes-blue-500;
        margin-right: 8px;
        border-radius: 50%;
      }

      .sc-find-container-title-info-item-subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-top: 8px;
        color: $andes-gray-550;
      }
    }

    &-item:nth-child(2),
    &-item:nth-child(3) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 328px;
      margin-bottom: 24px;

      .icon-custom {
        padding: 5px;
        width: 30px;
        height: 30px;
        background: $andes-blue-200;
        margin-right: 8px;
        border-radius: 50%;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      & > .andes-typography {
        display: block;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin: 0;

        &:last-child {
          font-size: 14px;
          font-weight: 400;
          padding-top: 8px;
          line-height: 16px;
          color: $andes-gray-550;
        }
      }

      & > .andes-typography.label {
        margin-right: auto;
      }
    }
  }
}

.sc-initial-cbt-recos {
  flex-direction: column;
  align-items: center;

  &-image {
    width: 100%;
  }

  &-info {
    max-width: 300px;
    padding-top: 48px;

    &-item .andes-typography.andes-typography--type-body {
      padding-top: 8px;
      font-size: 14px;
    }
  }
}
