.sc-reco-tooltip,
.sc-reco-card-tooltip {
  width: 20px;
  height: 20px;
  margin-left: 8px;

  & > div {
    width: 100%;
    height: 100%;
  }

  .andes-tooltip__trigger {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $andes-gray-040;

    .icon-custom {
      padding: 0.5px 2px;

      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.sc-reco-card-tooltip.just-icon {
  background-color: $andes-white;
  border-radius: 50%;
  cursor: default;

  .icon-custom {
    padding: 3.2px 2px 3.2px 0;

    & > svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

.sc-reco-tooltip.just-icon {
  margin-top: 16px;
}

.sc-reco-card-tooltip {
  position: absolute;
  top: -10px;
  right: 9px;
  z-index: 9;
  margin-top: 18px;
  width: 30px;
  height: 30px;

  .sc-global-tooltip-button {
    background-color: $andes-white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    .icon-custom {
      padding: 2.3px 2px;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .sc-modal-container.tooltip-mobile {
    display: none;
  }

  .andes-tooltip__trigger {
    background-color: $andes-white;
  }

  .andes-tooltip__trigger .icon-custom {
    padding: 3.2px 2px;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

.andes-tooltip.andes-tooltip--cards_container {
  padding: 24px 20px;
  max-width: 258px;

  .andes-tooltip {
    &__content {
      margin-right: 0;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }
}

.sc-modal-container__overlay.sc-global-tooltip-modal.tooltip-mobile {
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .sc-global-tooltip-dialog {
    width: 100%;
    max-width: 312px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .andes-button {
      border: none;
      height: 16px;
      width: 16px;
      padding: 0;
      align-self: end;
      margin-bottom: 16px;

      .icon-custom > svg path {
        fill: $andes-white;
        stroke: none !important;
      }
    }

    .sc-global-tooltip-dialog-content {
      background-color: $andes-white;
      padding: 24px;
      border-radius: 6px;

      .andes-list__item {
        font-size: 16px;
      }
    }

    .sc-global-tooltip-dialog-content .andes-typography--type-title {
      text-align: center;
    }
  }
}

.su-list-content-sites {
  .andes-list__item {
    padding: 0;
    height: 18px;
    display: flex;
    justify-content: start;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;

    .icon-custom {
      margin-right: 5px;
      width: 18px;
      height: 18px;

      & > svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.sc-guide-tooltip {
  width: 16px;
  height: 16px;
  margin: auto 0;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.tooltip-mobile.sc-guide-tooltip {
  margin-top: -2px;

  .sc-global-tooltip-button {
    background: none;
    border: none;
  }
}

.just-icon.sc-guide-tooltip {
  margin-top: initial;
}
