.sc-initial-information {
  display: flex;

  &-image {
    margin-top: -96px;
    width: 65%;
  }

  &-info {
    padding-top: 100px;

    &-item:first-child {
      margin-bottom: 40px;
      max-width: 328px;

      .sc-find-container-title {
        width: 100%;
        display: inline-flex;
        align-items: center;
      }

      .icon-custom {
        padding: 5px;
        width: 30px;
        height: 30px;
        background: $andes-blue-500;
        margin-right: 8px;
        border-radius: 50%;
      }

      .sc-find-container-title-info-item-subtitle {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        padding-top: 8px;
        color: $andes-gray-550;
      }
    }

    &-item:nth-child(2),
    &-item:nth-child(3) {
      display: flex;
      max-width: 328px;
      margin-bottom: 32px;

      .icon-custom {
        padding: 5px;
        width: 30px;
        height: 30px;
        background: $andes-blue-200;
        margin-right: 8px;
        border-radius: 50%;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }

      & > .andes-typography {
        display: contents;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;

        &:last-child {
          font-weight: 400;
          color: $andes-gray-550;
        }
      }
    }
  }
}

.sc-initial-cbt-recos {
  display: flex;
  flex-direction: row-reverse;

  &-image {
    width: 65%;
  }

  &-info {
    padding-top: 155px;

    &-item:first-child {
      margin-bottom: 40px;

      .icon-custom {
        padding: 5px;
        width: 30px;
        height: 30px;
        background: $andes-blue-200;
        margin-right: 8px;
        border-radius: 50%;

        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-item:last-child {
      .icon-custom {
        width: 60px;
        height: 30px;
        background: $andes-blue-200;
        margin-right: 8px;
        border-radius: 15px;

        & > svg {
          width: 59px;
          height: 30px;
        }
      }
    }

    &-item {
      .andes-typography.andes-typography--type-title {
        padding-top: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
      }

      .andes-typography.andes-typography--type-body {
        font-size: 18px;
        color: $andes-gray-550;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

@media (min-width: $desktop-s) {
  .sc-initial-information-info-item {
    &:nth-child(2) > .andes-typography.label::after,
    &:nth-child(3) > .andes-typography.label::after {
      content: ': ';
      display: contents;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
    }
  }
}

@media (max-width: $desktop-s) {
  @import './index.mobile';
}
