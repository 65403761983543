.floating-card,
.floating-card-completed {
  margin: 20px;
  top: -50px;
  margin-bottom: -50px;

  .andes-card__content {
    flex-direction: column;
    justify-content: center;
  }
}

.floating-info {
  text-align: center;

  h5 {
    font-size: $font-size-16 !important;
  }

  .floating-info-title,
  .floating-info-subtitle {
    margin: 0 !important;
    padding-bottom: 16px;
  }

  .floating-info-content {
    padding-bottom: 16px;
  }

  .floating-info-children {
    display: inline-block;
    text-align: start;
  }
}

.sc-view-home {
  .floating-card {
    margin-top: 64px;
    margin-bottom: 0.5rem;
  }

  .floating-card .floating-info .andes-typography {
    margin-top: 16px;
    line-height: normal;
  }
}

.sc-view-search .sc-floating-card-mobile {
  margin: 40px 24px 32px;
  text-align: center;
  background-color: $andes-gray-040-solid;

  h5 {
    line-height: 20px;
  }
}

.sc-view-search {
  .floating-card {
    order: 1;
    margin: 40px 20px 0;
    top: 0;
  }
}

.floating-card-resume {
  display: none;
}
