// sass-lint:disable class-name-format
@import '../styles/common';
@import '../components/Tag';
@import '../components/HomeHeader';
@import '../components/CardContainer';
@import '../components/CtaButton';
@import '../components/IconText';
@import '../components/CardChild';
@import '../components/RecoContainer';
@import '../components/Carrousel';
@import '../components/Card';
@import '../components/CardIcon';
@import '../components/SectionContainer';
@import '../components/InitialInformation';
@import '../components/FloatPanelCard';
@import '../components/Search';
@import '../components/GlobalTooltip';
@import '../components/Landing';

@import '../styles/common.desktop';

.first-steps-modal-image__modal {
  height: 406px !important;
  width: 480px !important;
}

.sc-home {
  .sc-view-home {
    width: 100%;
    margin: auto;

    // padding-top: 79px;
  }

  section:nth-child(6) {
    padding: 12px 0 84px;
  }

  .sc-find-information,
  .sc-find-information-cbt {
    background-image: linear-gradient($su-transparent, $andes-white),
      url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/gradient-E1F4FC.svg');
    background-position: right top;
    background-repeat: no-repeat !important;
    background-origin: border-box;
    background-size: cover;
    padding: 80px 0;

    .sc-section-container-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1129px;
      margin: 24px auto 45px;
    }

    &-cards {
      max-width: 1129px;
    }
  }

  .sc-section-container.sc-find-information-cbt {
    padding-bottom: 350px;
    margin-bottom: -300px;
  }

  .andes-card__content {
    margin-bottom: 25px;
    height: auto;
  }

  .sc-section-container.sc-categories-container {
    padding: 10px 0 23px;
  }

  @media (min-width: $desktop-s) {
    .floating {
      &-card {
        max-width: 1042px;
        margin-top: -120px;
        min-width: 901px;
        width: 90%;
        margin-bottom: 100px;
        top: 0;
      }

      &-info-title {
        font-size: $font-size-16;
      }
    }

    .sc-view-home {
      .sc-intro-container ~ .sc-categories-container {
        margin-bottom: 0;
      }
    }
  }
}

.sc-view-home.sc-cbt-view .sc-home-header-content .home-title.andes-typography {
  max-width: 470px;
  margin: auto auto 24px;
}

@media (max-width: $desktop-s) {
  .sc-section-container.sc-find-information,
  .sc-section-container.sc-find-information-cbt {
    padding-bottom: 1rem;
  }
}
