.floating-card,
.floating-card-completed {
  max-width: 888px;
  margin: 30px auto;
  top: -108px;
  margin-bottom: -108px;

  .andes-card__content {
    justify-content: space-between;
    height: 125px;
    @media (min-width: $desktop-m) {
      height: 130px;
    }
  }
}

.floating-info {
  .floating-info-title {
    padding-bottom: 8px;
  }

  .floating-info-content {
    padding-top: 8px;
  }

  .floating-info-children {
    display: flex;

    .icon-text {
      width: 50%;
    }
  }
}

.floating-info-with-children {
  padding-right: 78px;
}

.floating-info-image {
  padding-right: 24px;
}

.floating-card-resume {
  height: 73px;
  padding: 12px 42px;
  position: fixed;
  width: 100%;
  top: 56px;
  margin-bottom: -82px;
  border-radius: 0;
  z-index: 1;
  box-shadow: 0 1px 2px 0 $box-shadow;

  .cta-button.andes-button {
    min-width: 187px;
    height: 49px;
  }

  .andes-card__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }
}

.floating-card-completed {
  .floating-info-image {
    align-self: flex-start;
    padding-right: 16px;
  }

  .cta-button {
    padding: 0 20px;
    height: 54px;
  }
}
