// sass-lint:disable nesting-depth
.sc-home-header {
  background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/header-waves.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: cover, contain;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1280px;
    padding: 80px 0;
    text-align: center;

    .cta-button.outline {
      height: 54px;
      background-color: $andes-bg-white;
      margin: 20px 0;
      padding: 20px 16px 20px 20px;
      border-radius: 44px;
      min-width: 50px;

      .andes-button__text {
        font-size: $font-size-16;
      }
    }

    .cta-button.outline path {
      fill: $andes-blue-500 !important;
      stroke: none !important;
    }
  }

  &-content {
    text-align: center;
    width: 450px;

    .home-title.andes-typography,
    .home-subtitle.andes-typography {
      color: $andes-bg-white;
    }

    .home-title.andes-typography {
      margin-bottom: $andes-spacing-24;
      font-size: 40px;
      line-height: 40px;
      font-weight: $font-weight-bold;
    }

    .home-subtitle.andes-typography {
      line-height: 22px;
      font-size: $font-size-18;
      font-weight: $font-weight-regular;
    }
  }

  .sc-card-container {
    width: 1042px;
    justify-content: space-between;
    margin: 30px auto -108px;
    align-items: center;
    background: $andes-bg-white;
    filter: drop-shadow(0 6px 16px rgb(0 0 0 / 10%));
  }

  .sc-card-container .andes-thumbnail > img {
    height: 100%;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.sc-home-header-login {
  .sc-home-header-content {
    width: 748px;
    display: flex;
    flex-direction: column;
    margin-top: -10px;

    h1 {
      order: 1;
      padding-top: 8px;
    }

    .andes-typography.home-subtitle {
      order: 0;
      font-size: $font-size-20;

      @media (max-width: $tablet-s) {
        font-size: $font-size-18;
      }
    }
    @media (min-width: $desktop-l) {
      margin-top: -18px;
    }
  }

  .sc-card-container {
    border: none;
    box-shadow: none;
  }
}

@media (min-width: $desktop-s) and (max-width: $desktop-m) {
  .sc-home-header-container {
    max-width: 900px;

    .sc-home-header-content {
      max-width: 900px;
      margin-top: -10px;
    }
  }
}

@media (min-width: $desktop-m) and (max-width: $desktop-l) {
  .sc-home-header-container {
    max-width: 1042px;

    .sc-home-header-content {
      max-width: 1042px;
      margin-top: -18px;
    }
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}
