.sc-card-icon {
  border-right: none;
  border-bottom: 1px solid $andes-gray-100;
  width: 100%;
  padding-bottom: 16px;

  .Book20 > svg {
    width: 30px !important;
    height: 30px !important;
  }

  &:last-child {
    border-left: none;
    border-bottom: none;
    padding-bottom: 0;
  }
}
