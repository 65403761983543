.sc-section-container {
  padding: 84px 0 0;

  &-content {
    flex-direction: column;
    margin-top: 55px;
    margin-bottom: 56px;
  }

  &-title.andes-typography--type-title {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 8px;
  }

  &-subtitle {
    font-weight: 400;
    font-size: 18px;
  }
}

.guide.sc-section-container {
  padding: 48px 0 74px;
  margin-top: 70px;

  .sc-card-container + .sc-reco-container {
    margin-bottom: 0;
  }

  .sc-section-container-content {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .sc-section-container-cards .sc-card-container {
    margin-top: 80px;
    max-width: unset;
    margin-bottom: 0;

    &-content .home-title {
      color: $andes-black;
    }

    &-text {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.sc-intro-container {
  padding: 70px 0 0;

  .sc-section-container-content {
    margin: 84px 0 48px !important;
  }
}

.sc-section-container.sc-categories-container {
  padding: 102px 0 23px;

  &-bg {
    background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/solid-E1F4FC.svg');
    background-position: right top;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    padding-bottom: 70px;
  }

  .sc-section-container-content {
    max-width: 1200px;
    margin: 50px auto 24px;

    .sc-section-container-categories {
      margin-top: 32px !important;

      .andes-tab__link {
        margin: 0 48px 0 0;
      }

      .andes-tab:active {
        background: none;
      }
    }
  }
}

.sc-profile-container.sc-section-container {
  margin: 80px 0 0;
  padding: 90px 0;
  background-repeat: round;
  background-origin: border-box;
  background-size: cover;

  .sc-reco-container-wrapper {
    padding: 80px 0;
  }

  .sc-section-container-content {
    margin: 0;
  }

  .sc-reco-container.left,
  .sc-reco-container.right {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-around;

    .sc-reco-container {
      &-left,
      &-right {
        margin-top: 64px;
      }

      &-image {
        width: 35%;
        object-fit: contain;
      }

      &-title.andes-typography--type-title {
        font-weight: $font-weight-semibold;
        font-size: $font-size-24;
      }

      &-subtitle.andes-typography--size-m {
        font-weight: $font-weight-regular;
        font-size: $font-size-16;
        color: $andes-gray-550;
      }

      &-cards {
        display: flex;
        margin-top: 20px;
      }
    }

    .sc-card-content {
      margin-right: 16px;
    }
  }

  .sc-reco-container.right {
    flex-direction: row-reverse;
    margin: 64px 0 0 !important;

    .sc-reco-container-right {
      margin-top: 56px;
    }
  }
}

.sc-section-container.sc-categories-container-bg {
  margin: 0;

  .sc-section-container-content {
    max-width: 1200px;
    margin: 40px auto 24px;
  }

  .sc-section-container-categories {
    .andes-tab__link {
      margin: 0 48px 0 0;
    }
  }
}

.sc-section-container-wrapper {
  .andes-spinner {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modules {
  .sc-section-container-content {
    margin: 80px 0 64px !important;
  }

  .sc-section-container-cards {
    .sc-reco-container-tag-title {
      align-items: center;

      .tag.outline {
        margin-top: 0;
      }

      .andes-typography--type-title {
        margin: 0;
      }
    }
  }
}

.sc-initial-information-info {
  &-item {
    .sc-find-container-title {
      .andes-typography--type-title {
        margin: 0;
      }
    }
  }
}

@media (min-width: $desktop-s) and (max-width: $desktop-m) {
  .guide.sc-section-container .sc-section-container-cards .sc-card-container {
    max-width: 900px;
    margin: 80px auto 0;
  }

  .sc-profile-container.sc-section-container {
    .sc-reco-container.left,
    .sc-reco-container.right {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}

@media (max-width: $desktop-m) {
  .sc-section-container-wrapper {
    max-width: 960px;
  }

  .sc-profile-container.sc-section-container {
    padding: 90px 0 120px;

    .sc-reco-container.right {
      margin-top: 80px;
    }

    .sc-reco-container.right {
      margin-top: 28px;
    }

    .sc-reco-container.left,
    .sc-reco-container.right {
      .sc-reco-container-left,
      .sc-reco-container-right {
        margin-top: 28px;
      }

      .sc-card-content {
        margin-right: 16px;
      }

      .sc-reco-container-image {
        margin-top: auto;
      }
    }
  }

  .sc-section-container.sc-categories-container,
  .sc-section-container.sc-categories-container-bg {
    padding: 50px 0 23px;

    .sc-section-container-content {
      max-width: 900px;
    }
  }
}
