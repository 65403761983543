@import '../Footer';

.landing-wrapper {
  height: calc(100vh - 3em - 66px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100px;
  }

  .landing-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
  }

  .landing-country {
    border-bottom: 0;
    font-size: 14px;
    margin: 8px 10px;
    padding: 10px 10px 10px 45px;
    position: relative;
    text-decoration: none;
    list-style: none;

    > a {
      font-size: 14px;
    }

    &-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    &::before {
      background: url('https://static.mlstatic.com/org-img/homesnw/img/flags@2x.png?v=4.0')
        no-repeat 0 0 transparent;
      content: '';
      display: block;
      height: 20px;
      width: 28px;
      position: absolute;
      top: 11px;
      left: 8px;
      background-size: 28px;
    }

    &.item-MLB::before {
      background-position: 0 -20px;
    }

    &.item-MCO::before {
      background-position: 0 -40px;
    }

    &.item-MCR::before {
      background-position: 0 -60px;
    }

    &.item-MLC::before {
      background-position: 0 -80px;
    }

    &.item-MDR::before {
      background-position: 0 -100px;
    }

    &.item-MEC::before {
      background-position: 0 -120px;
    }

    &.item-MLM::before {
      background-position: 0 -140px;
    }

    &.item-MPA::before {
      background-position: 0 -160px;
    }

    &.item-MPE::before {
      background-position: 0 -180px;
    }

    &.item-MLU::before {
      background-position: 0 -220px;
    }

    &.item-MLV::before {
      background-position: 0 -240px;
    }
  }
}

@media (max-width: $tablet-xl) {
  .landing-wrapper {
    .landing-country-list {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
