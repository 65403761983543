.sc-section-container {
  padding: 23px 0;

  &-content {
    flex-wrap: nowrap;
    padding: 0 20px;

    .icon-custom.sc-section-container-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      width: 40px;
      height: 40px;
    }

    .icon-custom svg {
      width: 24px;
      height: 24px;
    }
  }

  &-categories {
    margin-top: 24px;
  }

  &-title.andes-typography--type-title {
    width: calc(100% - 40px);
    padding-left: 8px;
    font-weight: $font-weight-bold;
    font-size: 24px;
  }

  &-cards .sc-reco-container:last-child {
    margin-bottom: 0;
  }
}

.sc-intro-container {
  .sc-section-container-content {
    margin-bottom: 20px;
  }
}

.sc-section-container.sc-categories-container {
  padding: 0 0 23px;
  margin-bottom: 17px;

  &-bg {
    background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/solid-E1F4FC.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    padding-bottom: 70px;
    margin-top: 50px;
    margin-bottom: 0;
  }

  .sc-section-container-content {
    margin: 0 auto 24px;

    .andes-typography--type-title {
      padding: 0;
    }

    .sc-section-container-categories {
      margin-top: 20px;
    }
  }
}

.modules.sc-section-container {
  padding: 29px 0;
}

.sc-section-container.guide {
  .sc-section-container-content {
    align-items: flex-start;
    padding-top: 50px;

    .andes-typography--type-title {
      margin: 0;
    }
  }

  .sc-card-container {
    margin: 0 20px;
    max-width: unset;
    padding: 30px 0;
    background-color: $andes-white;

    .home-title {
      color: $andes-black;
    }
  }
}

.sc-section-container.sc-profile-container {
  padding: 80px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1200px 100%;

  .sc-section-container-content {
    margin-bottom: 40px;
  }

  .sc-reco-container-wrapper {
    padding: 80px 0;
  }

  .sc-reco-container.left,
  .sc-reco-container.right {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: space-around;

    .sc-reco-container {
      &-image {
        width: 35%;
      }

      &-title.andes-typography--type-title {
        font-weight: $font-weight-semibold;
        font-size: $font-size-24;
      }

      &-subtitle.andes-typography--size-m {
        font-weight: $font-weight-regular;
        font-size: $font-size-16;
        color: $andes-gray-550;
      }

      &-cards {
        display: flex;
        margin-top: 20px;
      }
    }

    .sc-card-content {
      margin-right: 20px;
    }

    .sc-card-content:last-child {
      margin-right: 0;
    }
  }

  .sc-reco-container.right {
    flex-direction: row-reverse;
  }
}

.sc-section-container-fallback-subtitle {
  width: 170px;
}

.sc-section-container.sc-find-information,
.sc-section-container.sc-find-information-cbt {
  padding-top: 60px;

  .sc-section-container-content.center {
    text-align: center;
    width: 100%;
    padding-bottom: 32px;

    .andes-typography {
      padding-left: 0;
      margin: auto;
    }
  }
}

.sc-section-container.sc-categories-container-bg .sc-section-container-content {
  margin: 24px auto;
}

@media (min-width: $tablet-s) {
  .sc-section-container.sc-categories-container {
    .sc-section-container-content {
      margin-top: 24px;
    }
  }
}
